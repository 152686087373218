import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { base_url } from '../baseURL';
import Footer from '../main-components/Footer';
import Header from '../main-components/Header';
import { slidersAddData, blogAddData, categoriesAddData, collapsesAddData, interiorsAddData, serviceAddData, settingAddData, welcomeAddData } from '../redux/sliceData/sliceData';
// import Hero from './sub-component/home/Hero';
import Banner from './sub-component/home/Banner';
import HomeMain from './sub-component/home/Home_main';
import { Helmet } from 'react-helmet';


export default function Home() {

  const dispatch = useDispatch();

  useEffect(() => {

    ; (async () => {
      const response = await fetch(`${base_url}/settings`);
       
      const data = await response.json();
      dispatch(settingAddData(data.settings));

      const response1 = await fetch(`${base_url}/colspan`);
      const data1 = await response1.json();
      dispatch(collapsesAddData(data1.colspan));

      const response2 = await fetch(`${base_url}/blog`);
      const data2 = await response2.json();
      dispatch(blogAddData(data2.blog));

      const response3 = await fetch(`${base_url}/welcomes`);
      const data3 = await response3.json();
      dispatch(welcomeAddData(data3.welcomes));

      const response4 = await fetch(`${base_url}/services`);
      const data4 = await response4.json();
      dispatch(serviceAddData(data4.services));

      const response5 = await fetch(`${base_url}/interiors`);
      const data5 = await response5.json();
      dispatch(interiorsAddData(data5.interior));

      const response6 = await fetch(`${base_url}/categories`);
      const data6 = await response6.json();
      dispatch(categoriesAddData(data6.categories));

      const response7 = await fetch(`${base_url}/sliders`);
      const data7 = await response7.json();
      dispatch(slidersAddData(data7.sliders));


    })();

  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Construction Company in Delhi & Gurgaon. Jain Developers
About Jain Developers | Best Interior Designers in 2023.
Property Collaboration Services in Delhi NCR & Gurgaon.
Turnkey Construction in Delhi NCR & Gurgaon | Jain Developers.
Top real estate developers in Delhi NCR & Gurgaon | Contact us.
Discover our Turnkey and Collaborative Projects Jain Developers</title>
<meta name="description" content="Best Home Construction Companies in Delhi NCR & Gurgaon. MCG MCD map sanction, liaison service & Demolition in Delhi Gurgaon. | Jain Developers.
For more than 2 decades, Jain Developers has been Delhi's & Gurgaon renowned developers for its quality of constructions. Contact us for more details.
Jain Developers is trusted partner for property collaborations in Delhi NCR and Gurgaon. Specialize in turnkey solutions for residential and commercial projects.
Jain Developers is top Turnkey Construction developers in Delhi NCR and Gurgaon that handle entire construction process with your budget.
Jain Developers is leading in Collaboration and Turnkey developers in Delhi & Gurgaon. Do follow contact us for your dream project in your budget.
Explore our portfolio of completed turnkey and collaborative projects. From luxurious residential complexes to cutting-edge commercial spaces."/>

        
        {/* <meta name="description" content="Most trusted Developers in Delhi" /> */}
        <link rel='canonical' href='https://jaindevelopers.in'/>

      </Helmet>
      <Header />

      {/* <Hero/> */}
      <Banner />


      <HomeMain />
      <Footer />
    </>
  )
}
