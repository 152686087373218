import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../../../main-components/Footer';
import Header from '../../../main-components/Header';
import { Helmet } from 'react-helmet';

// import products from '../data/re_dev_data';
import { ParallaxProvider } from 'react-scroll-parallax';
import Parallaximg from './Parallaximg2';
const TurnkeyProjects = () => {
  // const { productId } = useParams();
  // const product = products.find((product) => product.id === productId);
  // const { image, name, desc } = product;
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Construction Company in Delhi & Gurgaon. Jain Developers
About Jain Developers | Best Interior Designers in 2023.
Property Collaboration Services in Delhi NCR & Gurgaon.
Turnkey Construction in Delhi NCR & Gurgaon | Jain Developers.
Top real estate developers in Delhi NCR & Gurgaon | Contact us.
Discover our Turnkey and Collaborative Projects Jain Developers</title>
<meta name="description" content="Best Home Construction Companies in Delhi NCR & Gurgaon. MCG MCD map sanction, liaison service & Demolition in Delhi Gurgaon. | Jain Developers.
For more than 2 decades, Jain Developers has been Delhi's & Gurgaon renowned developers for its quality of constructions. Contact us for more details.
Jain Developers is trusted partner for property collaborations in Delhi NCR and Gurgaon. Specialize in turnkey solutions for residential and commercial projects.
Jain Developers is top Turnkey Construction developers in Delhi NCR and Gurgaon that handle entire construction process with your budget.
Jain Developers is leading in Collaboration and Turnkey developers in Delhi & Gurgaon. Do follow contact us for your dream project in your budget.
Explore our portfolio of completed turnkey and collaborative projects. From luxurious residential complexes to cutting-edge commercial spaces."/>

                <link rel='canonical' href='https://jaindevelopers.in/turnkey-projects'/>
            </Helmet>
      <Header />
      <section className='filter-area'>
      <ParallaxProvider>
        <Parallaximg className="filter" imgsrc="../assets/Images/gal-pic3.jpeg" opacity="0.5">
          <section className="contact-bg parallax abt-parallax">

            <div className="social-bg parallax-window1">
              <div className="wrapper1">
                <div className="rel-bx">
                  <div className="breadcrumb-border">

                  </div>
                </div>
                <div className="pt-5 ps-5">
                  <div className="swipe">
                    <h1>Turnkey Projects</h1>
                  </div>
                </div>

              </div>
              <div className="wrapper23">
                <div className="rel-bx z-ind">
                  <div className="line-1"></div>
                  <div className="line-2"></div>
                  <div className="line-3"></div>
                </div>
                <div className="box-121">

                </div>
              </div>

            </div>
          </section>
        </Parallaximg>



      </ParallaxProvider>
      </section>

      <section className="inside pt-80">
        <div className="row">
          {/* <h4>{title}</h4> */}
          <div className="col-lg-5">
            <div className="inside-desc inside-pad">
              <div className="inside-txt">

                {/* <h4>{id}</h4> */}
                <p>Our Approach


                </p>
              </div>
              <div className="inside-list mb-40">
                <ul>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Concept Design</span>
                  </li>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Map Sanction</span>
                  </li>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Front Elevation Design</span>
                  </li>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Structure Completion</span>
                  </li>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Interior Work</span>
                  </li>
                  <li>
                    <span className="inside-check"><i className="fa-solid fa-check"></i></span>
                    <span className="inside-detail">Warranty</span>
                  </li>
                </ul>
              </div>
              {/* <div className="inside-button">
                <button className="btn-more d-block">MORE</button>
              </div> */}
            </div>
          </div>
          <div className="col-lg-7 my-auto">
            <div className="inside-img">
              <img width="100%" src="../assets/Images/turnkey-main.png" alt="Jain developers turnkey projects" />
            </div>

          </div>
        </div>
      </section>

      {/* inside ends */}

      {/* <!-- hello section starts --> */}

      <section className="hello bg-white pt-140 pb-140">
        <div className="container ">
          <div className="hello-container">
            <div className="hello-txt">
              <h4>Behind the scene during Turnkey Projects</h4>
              <p>Being a Turnkey construction company in Delhi NCR, we have been doing turnkey construction projects of modern
                and traditional houses. We cater to all the dream home construction requirements of
                <li>
                  <span className="inside-check"><i className="fa-solid fa-diagram-venn"></i></span>
                  <span className="inside-detail">Owners looking to build on their vacant residential plot</span>
                </li>
                <li>
                  <span className="inside-check"><i className="fa-solid fa-diagram-venn"></i></span>
                  <span className="inside-detail">Owners looking to demolish rebuild their homes in a cost effective and hassel free manner.</span>
                </li>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Hello section ends --> */}
      <section>
        <div className="row turnkey-slider turnkey-sl">
          <div className="col-lg-4">
            <div className="inside-slider">
              {/* <div id="carouselExampleIndicators" data-bs-pause="false" className="carousel slide"
          data-bs-ride="carousel"> */}
              <Carousel fade infinite={true} className="carousel slide">
                <Carousel.Item interval={4000}>
                  <img
                    className="d-block"
                    src="assets/Images/gal1-pic5.jpeg"
                    alt="Turnkey Projects - kitchen"
                  />

                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img
                    className="d-block w-100"
                    src="assets/Images/gal1-pic3.jpeg"
                    alt="Turnkey Projects - bathroom"
                  />


                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img
                    className="d-block w-100"
                    src="assets/Images/gal1-pic8.jpeg"
                    alt="Turnkey projects - living room"
                  />


                </Carousel.Item>
              </Carousel>
              {/* <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="../assets/Images/item1.jpg" className="d-block " alt="..."/>
            </div>
            <div className="carousel-item">
              <img src="../assets/Images/item2.jpg" className="d-block" alt="..."/>
            </div>
            <div className="carousel-item">
              <img src="../assets/Images/item3.jpg" className="d-block" alt="..."/>
            </div>
          </div> */}

            </div>
          </div>
          {/* </div> */}

          <div className="col-lg-8 ps-0 my-auto pt-4">
            <div className="px-140">
              <div className=" over-cont">
                <div className="welcome-txt text-center mb-5">
                  <h4>How it works?</h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i className="fas fa-tape"></i>
                      </div>
                      <div className="contact-desc2">
                        <h6>Step - 1</h6>
                        <p>Construction areas are decided based on FAR or need</p>

                      </div>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i
                        className="fa-solid fa-file-signature"></i></div>

                      <div className="contact-desc2">
                        <h6>Step - 2</h6>
                        <p>Agreement is signed; the price and project duration are fixed
                        </p>

                      </div>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i className="fas fa-pencil-ruler"></i></div>

                      <div className="contact-desc2">
                        <h6>Step - 3</h6>
                        <p>Drawings begin and approvals are obtained. The contract is updated based on actual area.</p>

                      </div>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i className="fa-solid fa-igloo"></i>
                      </div>

                      <div className="contact-desc2">
                        <h6>Step - 4</h6>
                        <p>Construction begins</p>

                      </div>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i className="fa-solid fa-users-gear"></i>
                      </div>

                      <div className="contact-desc2">
                        <h6>Step - 5</h6>
                        <p>Regular meetings, track project progress and discuss remaining material and/ or design selections</p>

                      </div>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-box2 phone d-flex">
                      <div className="review-icon2 my-md-auto pt-2"><i className="fa-solid fa-truck-ramp-box"></i>
                      </div>

                      <div className="contact-desc2">
                        <h6>Step - 6</h6>
                        <p>Invitation for handover inspection once work is complete and complition certificate is issued.</p>

                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="inside-footprint">
        <img width="100%" height="100%" src="../assets/Images/inside-footprint.jpg" alt="Jain developers services" />
      </section>

      {/* <!-- Parallax area starts --> */}
      <ParallaxProvider>
        <Parallaximg imgsrc="../assets/Images/parallax2.jpg" opacity="0.5">
          <section className="contact-bg parallax">

            <div className="social-bg parallax-window">
              {/* <!-- <h1 className="info" style="color: #ffffff">Contact Us</h1> --> */}
              <div className="parallax-container">
                <div className="container text-center">
                  <div className="heading2">
                    <h2>Let's Start The Work</h2>
                    <p>Contact us and we will get in touch with you immediately.</p>
                  </div>


                  <div className="btn-x ">
                    <Link to="/contact" className="parallax-btn">CONTACT US</Link>

                  </div>
                </div>
              </div>
              <div className="overlay-white"></div>

            </div>

          </section>
        </Parallaximg>



      </ParallaxProvider>
      {/* <!-- Parallax area ends --> */}




      {/* <!-- modal section starts --> */}
      <section className="modal-sec">
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="modal-sec-txt  py-50">
              <div className="welcome-txt">
                <h4>Take a look at our completed work</h4>
              </div>
              <div className="far-txt">
                <p>Creating best in the class that are engaging, beautiful and sustainable.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="modal-gallery">
              <div className="row">

                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic3.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal2" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic4.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal3" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic5.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal4" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic6.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal5" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic2.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 p-0">
                  <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal6" className="modal-link">
                    <div className="modal-gal-img">
                      <img src="../assets/Images/gal-pic8.jpeg" alt="Jain Developers Projects" />
                      <div className="modal-overlay"></div>
                    </div>

                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- modal section ends --> */}



      {/* <!-- Modal 1 starts --> */}
      <div className="modal modal1 fade" id="exampleModal1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../assets/Images/gal-pic3.jpeg" className="img-responsive img-centered" alt="Jain Developers Projects" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- modal 1 ends --> */}


      {/* <!-- Modal 2 starts --> */}
      <div className="modal modal1 fade" id="exampleModal2" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../assets/Images/gal-pic4.jpeg" className="img-responsive img-centered" alt="Jain Developers Projects" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal 2 ends --> */}
      {/* <!-- Modal 3 starts --> */}
      <div className="modal modal1 fade" id="exampleModal3" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../public/Images/gal-pic5.jpeg" className="img-responsive img-centered" alt="Jain Developers Projects" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal 3 ends --> */}
      {/* <!-- Modal 4 starts --> */}
      <div className="modal modal1 fade" id="exampleModal4" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../assets/Images/gal-pic6.jpeg" className="img-responsive img-centered" alt="Jain Developers Projects" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal 4 ends --> */}
      {/* <!-- Modal 5 starts --> */}
      <div className="modal modal1 fade" id="exampleModal5" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../assets/Images/gal-pic2.jpeg" className="img-responsive img-centered" alt="" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal 5 ends --> */}
      {/* <!-- Modal 6 starts --> */}
      <div className="modal modal1 fade" id="exampleModal6" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 text-center fltt">
                    <img src="../assets/Images/gal-pic8.jpeg" className="img-responsive img-centered" alt="" />
                    <h2>CORPORATE DESIGN</h2>
                    <hr className="colored" />
                  </div>
                  <div className="col-lg-2"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal 6 ends --> */}


      <Footer />
      {/* <Footer/> */}
      {/* <section className='section product'>
      <img src={image} alt={name} />
      <h5>{name}</h5>
      <Link to='/products'>back to products</Link>
    </section> */}
    </>

  );
};

export default TurnkeyProjects;