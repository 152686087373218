import React, { useEffect, useState } from 'react';
import {NavLink, Link } from 'react-router-dom';
// import img1 from './skyscraper_white.png';
// import img2 from './skyscraper.png';
import { web_url } from '../baseURL';
import { new_base_url } from '../baseURL';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function Header() {
	

	const settingData = useSelector(state => state.sliceData.settings);

	
	// const settingData = useSelector(state => state.sliceData.settings
		
	// 	);
	// 	console.log(settingData)
	
  return (
    <>
    <header className="header">
		<nav className="navbar navbar-expand-lg">
			<div className="container">
				<Link className="navbar-brand" to="/">
					<img className="im-1" src={`${web_url}/${settingData[2]?.value}`} alt="Jain Developers"/>
					<img className="im-2" src={`${web_url}/${settingData[2]?.value}`} alt="Jain Developers"/> 
				</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
					data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false"
					aria-label="Toggle navigation">
					<i className="fas fa-bars"></i>
				</button>
				<div className="collapse navbar-collapse" id="navbarTogglerDemo01">

					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} aria-current="page" to="/">Home</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} to="/about">About Us</NavLink>
						</li>
						
						{/* <li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} to="/services">Services</NavLink>
						</li> */}


<li className="nav-item dropdown">
          <NavLink className={({isActive}) => (isActive ? "active1 nav-link dropdown-toggle" : 'nav-link dropdown-toggle')} to="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services 
          </NavLink>
          <ul className="dropdown-menu " aria-labelledby="navbarDarkDropdownMenuLink">
            <li><Link className="dropdown-item" to="/turnkey-projects">Turnkey Projects</Link></li>
            <li><Link className="dropdown-item" to="/collaborations">Collaborations</Link></li>
            
          </ul>
        </li>
						<li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} to="/projects">Projects</NavLink>
						</li>
						{/* <li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} to="/far-calculator">FAR Calculator</NavLink>
						</li> */}

				
						<li className="nav-item">
							<NavLink className={({isActive}) => (isActive ? "active nav-link" : 'nav-link')} to="/contact">Contact Us</NavLink>
						</li>
					</ul>

				</div>
			</div>
		</nav>
	</header>
    </>
  )
}
