// import { webpack } from "webpack";
// import https from 'https'
// import https from 'https-browserify'

export const base_url = 'https://dashboard.jaindevelopers.in/admin/api';
export const web_url = 'https://dashboard.jaindevelopers.in/admin/storage/app/public';

export const new_base_url='https://dashboard.jaindevelopers.in/admin/api'



// const httpsAgent = new https.Agent({ rejectUnauthorized: false });