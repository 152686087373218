import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import {  web_url } from '../../../baseURL';
import { new_base_url } from '../../../baseURL';
import axios from 'axios';

export default function Banner() {
	const [slidersData,setsliderData]=useState([])
	const [settingData,setSettingData]=useState([])
	const api_call=async()=>{
		 axios.get(new_base_url+'/settings').then(res=>{
				setSettingData(res.data.settings)
// console.log(res.data.settings)
			}).catch(e=>{
				// console.log(e)
				if(e.response.status===429){
					setTimeout(()=>{
						axios.get(new_base_url+'/settings').then(res=>{
							setSettingData(res.data.settings)
			// console.log(res.data.settings)
						})
					},1000)
				}
			})
		}

		const api_call2=async()=>{
			await  axios.get(new_base_url+'/sliders').then(res=>{
				setsliderData(res.data.sliders)
				// console.log(res)
			}).catch(e=>{
				if(e.response.status===429){
					setTimeout(()=>{
						axios.get(new_base_url+'/sliders').then(res=>{
							setsliderData(res.data.sliders)
							// console.log(res)
						})	
					},1000)
				}
			})
		}


		

		useEffect(()=>{
			api_call2()

			setTimeout(()=>{
				api_call()
			},200)
		},[])
	// const settingData = useSelector(state => state.sliceData.settings);
	// const slidersData = useSelector(state => state.sliceData.sliders);
	// console.log("Sliders Data", slidersData);
  return (
    <>
        <div className="banner three">
		{/* <div className="banner-overlay"></div> */}
	<Carousel fade infinite={true} >
		{slidersData.map((slider, index) => (
      <Carousel.Item interval={4000} key={index}>
        <img
          className="d-block w-100"
          src={`${web_url}/${slider.image}`}
          alt="Jain Developers - Most trusted developers for your dream home"
        />
        </Carousel.Item>
	    ))}
    </Carousel>
		<div className="text-container ">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6">
						<div className="row">
							<div className="col-md-6 line">
								<div className="sky-head text-center">
									<h2>{settingData[20]?.value}</h2>
								</div>
							</div>
							<div className="col-md-6">
								<div className="sky-para text-center text-md-start">
									<p>{settingData[21]?.value}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="row">
							<div className="col-lg-4">

							</div>
							<div className="col-lg-4">

							</div>
							<div className="col-lg-4 col-md-6 banner-low  text-center">
								<div className="banner-icon">
									<i className="far fa-building"></i>
								</div>
								<div className="banner-number nums">
									<h3>
										{/* <span className="num" ><CountUp end={settingData[23]?.value} duration={1.5}/></span>+  */}
										{/* <br/> */}
										2Lac+ Sq. Ft Construction Experience</h3>
								</div>
							</div>
							{/* <div className="col-lg-4 col-md-6 banner-low text-center">
								<div className="banner-icon">
									<i className="fas fa-chart-area"></i>
								</div>
								<div className="banner-number nums">
									
									<h3><span className="num"><CountUp end={settingData[23]?.value} duration={2}/></span>+ <br/> Grass Area</h3>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </>
  )
}
