const products = [
    {
      id: '1',
      name: 'Commercial1',
      subtitle: 'condo',
      image:
        '../assets/Images/project3.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '2',
      name: 'Luxary Residence2',
      subtitle: 'condo',
      image:
        '../assets/Images/project2.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '3',
      name: 'Luxary Residence3',
      subtitle: 'condo',
      image:
        '../assets/Images/here.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '4',
      name: 'Luxary Residence4',
      subtitle: 'condo',
      image:
        '../assets/Images/project4.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '5',
      name: 'Luxary Residence5',
      subtitle: 'condo',
      image:
        '../assets/Images/project5.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '6',
      name: 'Luxary Residence6',
      subtitle: 'condo',
      image:
        '../assets/Images/project6.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
    {
      id: '7',
      name: 'Luxary Residence7',
      subtitle: 'condo',
      image:
        '../assets/Images/project3.jpg',
        desc:'Alway in fashion empire of the sun, myki queues footy fed square melbourne cricket ground melbourne central, lions bar.',
    },
  ];
  
  export default products;