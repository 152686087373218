import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { base_url } from '../baseURL';
import Footer from '../main-components/Footer';
import Header from '../main-components/Header';
import { ourservicesData, servicelistData, servicesmarksData, whatweoffersData } from '../redux/sliceData/sliceData';
import ServicesBanner from './sub-component/services/Services_banner';
import ServicesMain from './sub-component/services/Services_main';
import { Helmet } from 'react-helmet';

export default function Services() {
  const dispatch = useDispatch()

  useEffect(() => {
      // Service Function
      ;(async () => {
          const response1 = await fetch(`${base_url}/whatweoffers`)
          const data1 = await response1.json()
          dispatch(whatweoffersData(data1.whatweoffers))

          const response2 = await fetch(`${base_url}/serviceslist`)
          const data2 = await response2.json()
          dispatch(servicelistData(data2.serviceslist))

          const response3 = await fetch(`${base_url}/ourservices`)
          const data3 = await response3.json()
          dispatch(ourservicesData(data3.ourservices))

          const response4 = await fetch(`${base_url}/servicesmarks`)
          const data4 = await response4.json()
          dispatch(servicesmarksData(data4.servicesmarks))
      })()
  },[])

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Construction Company in Delhi & Gurgaon. Jain Developers
About Jain Developers | Best Interior Designers in 2023.
Property Collaboration Services in Delhi NCR & Gurgaon.
Turnkey Construction in Delhi NCR & Gurgaon | Jain Developers.
Top real estate developers in Delhi NCR & Gurgaon | Contact us.
Discover our Turnkey and Collaborative Projects Jain Developers</title>
<meta name="description" content="Best Home Construction Companies in Delhi NCR & Gurgaon. MCG MCD map sanction, liaison service & Demolition in Delhi Gurgaon. | Jain Developers.
For more than 2 decades, Jain Developers has been Delhi's & Gurgaon renowned developers for its quality of constructions. Contact us for more details.
Jain Developers is trusted partner for property collaborations in Delhi NCR and Gurgaon. Specialize in turnkey solutions for residential and commercial projects.
Jain Developers is top Turnkey Construction developers in Delhi NCR and Gurgaon that handle entire construction process with your budget.
Jain Developers is leading in Collaboration and Turnkey developers in Delhi & Gurgaon. Do follow contact us for your dream project in your budget.
Explore our portfolio of completed turnkey and collaborative projects. From luxurious residential complexes to cutting-edge commercial spaces."/>

                {/* <link rel='canonical'/> */}
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
    <Header/>
    <ServicesBanner/>
    <ServicesMain/>

    <Footer/>
    </>
  )
}
