import React from 'react'

export default function Projects_typeBanner() {
  return (
    <>
    <section className="contact-bg parallax abt-parallax">

<div className="social-bg parallax-window1" data-parallax="scroll" data-z-index="1"
    data-image-src="assets/Images/pexels-jean-van-der-meulen-1457842.jpg">
    <div className="wrapper1">
        <div className="rel-bx">
            <div className="breadcrumb-border">

            </div>
        </div>
        <div className="pt-5 ps-5">
            <div className="swipe">
                <h1>Projects type</h1>
            </div>
        </div>
        
    </div>
    <div className="wrapper23">
        <div className="rel-bx z-ind">
<div className="line-1"></div>
<div className="line-2"></div>
<div className="line-3"></div>
        </div>
        <div className="box-121">

        </div>
    </div>
    {/* <!-- <h1 className="info" style="color: #ffffff">Contact Us</h1> --> */}
</div>
</section>
    </>
  )
}
