import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { base_url, web_url } from '../../../baseURL'
import { aboutweareData, teamAddData, testimonialAddData } from '../../../redux/sliceData/sliceData'

export default function About_main() {

    const dispatch = useDispatch()

    useEffect(() => {
        // Team Function
        ;(async () => {
            const response1 = await fetch(`${base_url}/team`)
            const data1 = await response1.json()
            dispatch(teamAddData(data1.teamMembers))

        // Testimonials Function
            const response2 = await fetch(`${base_url}/testimonials`)
            const data2 = await response2.json()
            dispatch(testimonialAddData(data2.testimonials))

        // about we are
        const response3 = await fetch(`${base_url}/getaboutweare`);
        const data3 = await response3.json();
        dispatch(aboutweareData(data3.aboutweare));
        })()
    },[])

    // const teamMembers = useSelector(state => state.sliceData.team)
    const testimonials = useSelector(state => state.sliceData.testimonials)
    const aboutweare = useSelector(state => state.sliceData.aboutweare)

    return (
        <>
            <section className="about-bg pt-140 pb-140 ">

                <div className="overlay-white"></div>
                <div className="container over-cont">

                    <div className="abt-welcome-txt">
                        <h6>Experties &amp; Passion</h6>
                        <h4>About Us</h4>
                        <p className="mt-3 mb-0">For more than 2 decades, JAIN DEVELOPERS has been Delhi's renowned developers for its quality of constructions.
                        We have got an art in construction keeping the owner's lifestyle in mind, featuring every amenity that allows the owner to make the most of every moment. Delivering projects on time with best suited materials and fixtures elevates every experience.
                        We at Jain Developers always try to give unique aesthetics in each project.
                        </p>
                    </div>
                    {/* <div className="row">
                        {teamMembers?.map((member, index) => (
                        <div className="col-md-4 p-4" key={index}>
                            <a className="img-hov" href="#">
                                <div className="team-pic">
                                    <img width="100%" src={`${web_url}/${member?.image}`} alt="" />
                                </div>
                            </a>
                            <div className="team-txt text-center">
                                <h4>{member.name}</h4>
                                <p>{member.position}</p>
                            </div>
                        </div>
                        ))}
                    </div> */}
                </div>
            </section>

            {/* <!-- video section starts --> */}

            {/* <section className="video-bg">
                <div className="video-container">
                    <video type="video/mp4"  src="assets/Images/pexels-kindel-media-7577733.mp4" className="video" autoPlay loop muted/>
                        
                </div>
                <div className="container test-co pt-100 pb-100">
                    <div className="reviews">
                        <div className="heading-box d-flex">
                            <div className="review-icon"><i className="pt-3 fa-solid fa-user"></i></div>
                            <div>
                                <div className="review-txt">
                                    <h6>a word from our clients</h6>
                                    <h4>Testimonials</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-30">
                        {testimonials?.map((testimonial, index) => (
                        <div className="col-md-6 mb-20" key={index}>
                            <div className="testimonial-box border-light">
                                <div className="review-txt text-center">
                                    <p>{testimonial.message}</p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="profile-pic">
                                        <img src={`${web_url}/${testimonial?.logo}`} alt="" />
                                    </div>
                                    <div className="profile-detail pt-2 ps-2">
                                        <h6>{testimonial.name}</h6>
                                        <p>{testimonial.position}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>

                </div>
                <div className="color-overlay"></div>
            </section> */}
            {/* <!-- video section ends --> */}


            {/* <!-- panaroma starts --> */}
            <section className="slider-sec about-last-sec">
                <div className="row">
                    <div className="col-lg-5 my-auto">
                        <div className="welcome-txt px-90">

                            <h4>{aboutweare?.title}</h4>
                            <p className="mt-3">{aboutweare?.content}</p>
                        </div>
                    </div>
                    <div className="col-lg-7 mb-0 pe-0">
                        {/* <!-- Banner links starts here --> */}

                        <div className="abt-img-cont">
                            <img src={`${web_url}/${aboutweare?.image}`} alt="Jain Developers" />
                        </div>

                        {/* <!-- banner links ends here --> */}
                    </div>
</div>
            </section>
            {/* <!-- Panaroma ends --> */}
        </>
    )
}
