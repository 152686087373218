import React, { useEffect } from 'react';
import Header from '../main-components/Header';
import Footer from '../main-components/Footer';
import FarBanner from './sub-component/far/FarBanner';
import FarMain from './sub-component/far/FarMain';
import { useDispatch } from 'react-redux';
import { base_url } from '../baseURL';
import { fardetailsData, faroursData } from '../redux/sliceData/sliceData';
import { Helmet } from 'react-helmet';

const FAR = () => {
  const dispatch = useDispatch()

  useEffect(() => {

      // FAR Function
      ;(async () => {

          const response1 = await fetch(`${base_url}/farours`)
          const data1 = await response1.json()
          dispatch(faroursData(data1.farours))

          const response2 = await fetch(`${base_url}/fardetails`)
          const data2 = await response2.json()
          dispatch(fardetailsData(data2.fardetails))

      })()
  },[])
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Construction Company in Delhi & Gurgaon. Jain Developers
About Jain Developers | Best Interior Designers in 2023.
Property Collaboration Services in Delhi NCR & Gurgaon.
Turnkey Construction in Delhi NCR & Gurgaon | Jain Developers.
Top real estate developers in Delhi NCR & Gurgaon | Contact us.
Discover our Turnkey and Collaborative Projects Jain Developers</title>
<meta name="description" content="Best Home Construction Companies in Delhi NCR & Gurgaon. MCG MCD map sanction, liaison service & Demolition in Delhi Gurgaon. | Jain Developers.
For more than 2 decades, Jain Developers has been Delhi's & Gurgaon renowned developers for its quality of constructions. Contact us for more details.
Jain Developers is trusted partner for property collaborations in Delhi NCR and Gurgaon. Specialize in turnkey solutions for residential and commercial projects.
Jain Developers is top Turnkey Construction developers in Delhi NCR and Gurgaon that handle entire construction process with your budget.
Jain Developers is leading in Collaboration and Turnkey developers in Delhi & Gurgaon. Do follow contact us for your dream project in your budget.
Explore our portfolio of completed turnkey and collaborative projects. From luxurious residential complexes to cutting-edge commercial spaces."/>

                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
    <Header/>
    <FarBanner/>
    <FarMain/>
    <Footer/>
    </>
  )
}

export default FAR