
import './App.css';
import RouterPage from './main-components/RouterPage';
import TagManager from 'react-gtm-module';



const tagManagerArgs = {
  gtmId: 'GTM-N6VSRLR'
}
TagManager.initialize(tagManagerArgs)




function App() {
  return (
    <div className="App">


     <RouterPage/>
    </div>
  );
}

export default App;
